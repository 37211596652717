import React, { useState, useEffect } from "react";
import PDFViewer from "./components/PDFViewer";
import PDFJSBackend from "./backends/pdfjs";
import "firebase/auth";
import firebase from "firebase";
import { v4 as uuidv4 } from "uuid";
import Bowser from "bowser";

import "./App.css";

const axios = require("axios").default;

const firebaseConfig = {
  apiKey: "AIzaSyAxuf_6bLrH8Acz3SUrwaGklDTSv2WOygg",
  authDomain: "access-pathway-tracking.firebaseapp.com",
  databaseURL: "https://access-pathway-tracking.firebaseio.com",
  projectId: "access-pathway-tracking",
  storageBucket: "access-pathway-tracking.appspot.com",
  messagingSenderId: "308678096841",
  appId: "1:308678096841:web:09634ccf63ca9add849a18",
  measurementId: "G-TQE15FKXEQ",
};

const getUrlParameter = (name) => {
  if (typeof window !== "undefined") {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
    var results = regex.exec(window.location.search);
    return results === null
      ? ""
      : decodeURIComponent(results[1].replace(/\+/g, " "));
  }
};

firebase.initializeApp(firebaseConfig);

function App() {
  const [pdfAsset, setPdfAsset] = useState("");
  let currentIp = "";

  useEffect(() => {
    const sessionId = uuidv4();
    const browserInfo = Bowser.getParser(window.navigator.userAgent);

    firebase
      .auth()
      .signInAnonymously()
      .catch(function (error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        console.log(errorCode, errorMessage);
        // ...
      });
    /*
      "/assets/email-briviact-commercial-flashcard-2256e595-8dd5-48b7-b27e-f16e34f3d751.pdf"
    */
    const assetParam = getUrlParameter("pdf");
    assetParam && setPdfAsset(assetParam);

    const fetchIp = async () => {
      try {
        // fetch data from a url endpoint
        const result = await axios.get(
          "https://www.cloudflare.com/cdn-cgi/trace",
          { timeout: 2000 }
        );

        let resultStr = "";
        if (result) {
          resultStr = result.data.split("\n").filter((item) => {
            return item.indexOf("ip") !== -1;
          })[0];
          resultStr = resultStr.substr(
            resultStr.indexOf("ip=") + 3,
            resultStr.length
          );
          currentIp = resultStr;
        }

        // return result.data;
      } catch (error) {
        console.log("error", error);
      }
    };

    fetchIp();
    window.onmessage = (_event) => {
      firebase.auth().onAuthStateChanged(function (user) {
        if (user) {
          // User is signed in.

          //Firebase is firinng this event so we have to make sure
          //we only care of our event
          //data from firebase starts with "!_{"
          if (_event) {
            if (_event.data.indexOf("!_{") === -1) {
              const trackData = {
                ...JSON.parse(_event.data),
                timestamp: Date.now(),
              };

              firebase
                .database()
                .ref("pdf-viewer/sessions/" + sessionId)
                .set({
                  pdf_name: assetParam,
                  session_id: sessionId,
                  browser_info: browserInfo.parsedResult,
                  client_ip: currentIp,
                });

              firebase
                .database()
                .ref("pdf-viewer/actions/" + sessionId)
                .push(trackData);

              //console.log("trackData", trackData);
            }
          }

          // ...
        } else {
          // User is signed out.
          // ...
        }
        // ...
      });
    };
  }, []);
  return (
    <div className="App">
      {pdfAsset && <PDFViewer backend={PDFJSBackend} src={pdfAsset} />}
    </div>
  );
}

export default App;
