import React, { useEffect, useRef } from "react";

function PDFViewer({ src, backend }) {
  const viewerRef = useRef(null);

  const backendObj = new backend();

  useEffect(() => {
    const element = viewerRef.current;
    backendObj && backendObj.init(src, element);
  }, []);
  return (
    <div
      ref={viewerRef}
      id="viewer"
      style={{ width: "100%", height: "100%" }}
    ></div>
  );
}

export default PDFViewer;
